<template lang="pug">
.absolute.shadow.z-30.bottom-5.top-5.left-5.bg-white.grid.grid-cols-1.pr-4.pl-6.pt-5.w-module.overflow-y-auto(
  style='grid-template-rows: auto auto auto 1fr'
)
  .notify.relative(style='width: 420px')
    .fixed.grid.justify-between.items-center.bg-white.top-5.width-full.z-50.pt-5
      .header.flex.justify-between
        .text-darkblue.col-start-1.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro {{ title === 1 ? $t("notifications.add") : $t("notifications.change") }}
        .flex.justify-end.col-start-2.items-center
          img.mr-5(
            src='../../assets/icons/history.svg',
            style='width: 18px',
            v-if='notRole && title !== 1',
            @click='$emit("show-history", { objects: "notifications", objectId: notification.id })'
          )
          i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
            @click='$emit("close")'
          )
      .btns
        .flex.justify-end.col-start-1.col-end-3.pb-3.mt-6
          skif-button(variant='normal', @click='$emit("close")') {{ $t("cancel") }}
          skif-button.ml-6(
            @click='save',
            :disabled='isAllowedCreate '
          ) {{ $t("btn.save") }}
      .tabs
        .flex.font-semibold.text-sm.font-SourceSansPro.pt-4.pb-6.col-start-1.col-end-4
          .pr-3.cursor-pointer.transition-colors.duration-300(
            @click='activeTab = "main"',
            :class='isMainError ? "text-notify" : activeTab === "main" ? "text-darkblue" : "text-annotationColor"'
          ) {{ $t("notifications.main") }}
          .px-3.cursor-pointer.transition-colors.duration-300(
            @click='activeTab = notification.type.key !== "alarm_notification" ? "extra" : activeTab',
            :class='[isSecondaryError ? "text-notify" : activeTab === "extra" && notification.type.key !== "alarm_notification" ? "text-darkblue" : "text-annotationColor", { "opacity-50 cursor-not-allowed": notification.type.key === "alarm_notification" }]'
          ) {{ $t("notifications.more") }}
          .px-3.cursor-pointer.transition-colors.duration-300(
            @click='activeTab = "delivery"',
            :class='isDeliveryError ? "text-notify" : activeTab === "delivery" ? "text-darkblue" : "text-annotationColor"'
          ) {{ $t("notifications.delivery") }}
          .px-3.cursor-pointer.transition-colors.duration-300(
            @click='activeTab = "text"',
            :class='isTextError ? "text-notify" : activeTab === "text" ? "text-darkblue" : "text-annotationColor"'
          ) {{ $t("notifications.text") }}
          .px-3.cursor-pointer.transition-colors.duration-300(
            @click='activeTab = "control"',
            :class='isControlError ? "text-notify" : activeTab === "control" ? "text-darkblue" : "text-annotationColor"'
          ) {{ $t("notifications.control") }}
  main-tab.col-start-1.col-end-4(
    style="margin-top: 160px"
    v-show='activeTab === "main"',
    ref='main',
    :notification='notification',
    :admin='admin'
  )
  velocityNotification.col-start-1.col-end-4(
    style="margin-top: 160px"
    v-show='activeTab === "extra"&&notification.type.key === "velocity_notification"',
    ref='velocity',
    @updateVelocityParams='updateVelocityParams',
    :isVelocityParamsMissing='isVelocityParamsMissing',
    :notification='notification'
  )
  geozoneNotification.col-start-1.col-end-4(
    style="margin-top: 160px"
    v-show='activeTab === "extra"&&notification.type.key === "geozone_notification"',
    ref='geozone',
    :notification='notification',
    @updateInGeozone='updateInGeozone',
    :isInGeozone='isInGeozone',
    :admin='admin'
  )
  sensorNotification.col-start-1.col-end-4(
    style="margin-top: 160px"
    v-show='activeTab === "extra"&&notification.type.key === "sensor_notification"',
    :notification='notification',
    :isSensorTypeOrMask='isSensorTypeOrMask',
    @updateSensorType='updateSensorType',
    :isIntervalMissing='isIntervalMissing',
    @updateInterval='updateInterval',
    :isSensorMoreLess='isSensorMoreLess',
    @updateSensorMoreLess='updateSensorMoreLess'
    @updateGeoControl='updateGeoControl'
  )
  trips-notification.col-start-1.col-end-4(
    style="margin-top: 160px"
    v-show='activeTab === "extra"&&notification.type.key === "trips_notification"',
    :notification='notification',
    :isTripsnamemask='isTripsnamemask',
    @update-tripsnamemask='updateTripsnamemask',
    :isOneCheckbox='isOneCheckbox',
    @update-isonecheckbox='updateIsonecheckbox'
  )
  lostsignalNotification.col-start-1.col-end-4(
    style="margin-top: 160px"
    v-show='activeTab === "extra"&&notification.type.key === "lostsignal_notification"',
    :isLostSignalNotLessMissing='isLostSignalNotLessMissing',
    @updateLostSignalNotLess='updateLostSignalNotLess',
    :notification='notification'
  )
  idleNotification.col-start-1.col-end-4(
    style="margin-top: 160px"
    v-show='activeTab === "extra"&&notification.type.key === "idle_notification"',
    :notification='notification',
    @updateIdleParamsMissing='updateIdleParamsMissing',
    :isIdleParamsMissing='isIdleParamsMissing'
  )
  serviceNotification.col-start-1.col-end-4(
    style="margin-top: 160px"
    v-show='activeTab === "extra"&&notification.type.key === "service_notification"',
    @updateIntervalDaysOrMilage='updateIntervalDaysOrMilage',
    :isIntervalDaysOrMilage='isIntervalDaysOrMilage',
    :notification='notification'
  )
  delivery.col-start-1.col-end-4(
    style="margin-top: 160px"
    ref='delivery',
    v-show='activeTab === "delivery"',
    :notification='notification',
    :emailMissing='emailMissing',
    :phoneMissing='phoneMissing',
    :pushMissing='pushMissing',
    :telegramMissing='telegramMissing',
    @updateEmailMissing='updateEmailMissing',
    @updatePhoneMissing='updatePhoneMissing',
    @updatePushMissing='updatePushMissing',
    @updateTelegramMissing='updateTelegramMissing'
  )
  notifyText.col-start-1.col-end-4(
    style="margin-top: 160px"
    v-show='activeTab === "text"',
    :notification='notification',
    :isTextMissing='isTextMissing',
    @updateText='updateText'
  )

  fillingNotification.col-start-1.col-end-4(
    style="margin-top: 165px; overflow: hidden"
    v-show='activeTab === "extra" && notification.type.key === "filling_notification" || activeTab === "extra" && notification.type.key === "draining_notification"',
    :notification='notification',
    :isSensorTypeOrMask='isSensorTypeOrMask',
    @updateSensorType='updateSensorType',
    :isIntervalMissing='isIntervalMissing',
    @updateInterval='updateInterval',
    :isSensorMoreLess='isSensorMoreLess',
    @updateSensorMoreLess='updateSensorMoreLess'
    @change-min-value-time="changeMinValueTime"
  )

  control.col-start-1.col-end-4(
    style="margin-top: 160px"
    v-show='activeTab === "control"',
    :notification='notification'
  )
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    mainTab: () => import('@/components/notifications/tabs/main'),
    delivery: () => import('@/components/notifications/tabs/delivery'),
    notifyText: () => import('@/components/notifications/tabs/notifyText'),
    control: () => import('@/components/notifications/tabs/control'),
    velocityNotification: () =>
      import('@/components/notifications/tabs/extra/velocityNotification'),
    geozoneNotification: () =>
      import('@/components/notifications/tabs/extra/geozoneNotification'),
    sensorNotification: () =>
      import('@/components/notifications/tabs/extra/sensorNotification'),
    lostsignalNotification: () =>
      import('@/components/notifications/tabs/extra/lostsignalNotification'),
    idleNotification: () =>
      import('@/components/notifications/tabs/extra/idleNotification'),
    serviceNotification: () =>
      import('@/components/notifications/tabs/extra/serviceNotification'),
    tripsNotification: () =>
      import('@/components/notifications/tabs/extra/tripsNotification.vue'),
    fillingNotification: () =>
      import('@/components/notifications/tabs/extra/fillingNotification.vue')
  },
  props: ['notificationToUpdate', 'title', 'admin'],

  data() {
    return {
      activeTab: 'main',
      notification: null,
      isTextMissing: false,
      // sensor-notif..
      isSensorTypeOrMask: false,
      isIntervalMissing: false,
      isSensorMoreLess: false,
      // service-notif..
      isIntervalDaysOrMilage: false,
      // trips
      isTripsnamemask: false,
      isOneCheckbox: false,
      // lostsignal-notif..
      isLostSignalNotLessMissing: false,
      // geozone-notif..
      isInGeozone: false,
      // idle-notif..
      isIdleParamsMissing: false,
      // velocity-notif..
      isVelocityParamsMissing: false,
      // КОНТРОЛЬ
      emailMissing: false,
      phoneMissing: false,
      pushMissing: false,
      telegramMissing: false,
      isMainError: false,
      isSecondaryError: false,
      isDeliveryError: false,
      isTextError: false,
      isControlError: false
    }
  },
  computed: {
    ...mapState('dictionary', {
      notificationTypes: (state) => state.notification_type,
      geozonesControl: (state) => state.geozones_control
    }),
    ...mapGetters('login', ['role', 'disabledRole', 'notRole']),
    isAllowedCreate() {
      return this.disabledRole && !this.isRoleAllowed && this.valid()
    },
    isRoleAllowed() {
      return this.role === 'EDITOR' || this.role === 'OPERATOR'
    },
    deliveryValid() {
      const n = this.notification
      const isEmpty = (arr = []) => {
        return !arr || !arr.length
      }
      let res =
        n.is_show_online ||
        n.isdeliveryemail ||
        n.isdeliverysms ||
        n.isdeliverypush ||
        n.isdeliverytelegram

      if (
        (n.isdeliveryemail && isEmpty(n.delivery_emails)) ||
        (n.isdeliverysms && isEmpty(n.delivery_sms)) ||
        (n.isdeliverypush && isEmpty(n.delivery_push)) ||
        (n.isdeliverytelegram && isEmpty(n.delivery_telegram))
      ) {
        res = false
      }
      return res
    }
  },
  watch: {
    'notification.is_in_tp_violation': function (val) {
      if (val) {
        this.notification.is_in_trip_points = true
      }
    },
    'notification.is_in_trip_points': function (val) {
      if (!val) {
        this.notification.is_in_tp_violation = false
      }
    },
    'notification.is_out_tp_violation': function (val) {
      if (val) {
        this.notification.is_out_trip_points = true
      }
    },
    'notification.is_out_trip_points': function (val) {
      if (!val) {
        this.notification.is_out_tp_violation = false
      }
    },
    'notification.type': function () {
      if (this.title === 1) {
        const notificationTypes = {
          service_notification: 'notifications.text.arrival',
          // geozone_notification: this.notification.in_geozone
          //   ? 'notifications.text.geozone.inside'
          //   : 'notifications.text.geozone.outside',
          sensor_notification: 'notifications.text.sensor',
          trips_notification: 'notifications.text.trip',
          velocity_notification: 'notifications.text.speed',
          lostsignal_notification: 'notifications.text.connection',
          idle_notification: 'notifications.text.idle',
          filling_notification: 'notifications.text.filling',
          draining_notification: 'notifications.text.drain'
        }

        const notificationKey = this.notification.type.key
        this.notification.notificationtext = this.$t(
          notificationTypes[notificationKey] || 'notifications.text.sensor'
        )
      }
    },
    'notification.in_geozone': function (val) {
      if (this.title === 1) {
        if (val) {
          this.notification.notificationtext = this.$t(
            'notifications.text.geozone.inside'
          )
        } else if (val === false) {
          this.notification.notificationtext = this.$t(
            'notifications.text.geozone.outside'
          )
        }
      }
    }
  },

  methods: {
    changeMinValueTime(val) {
      this.notification.min_trigger_duration_value = val
    },
    updateGeoControl(val) {
      const [currentControl] = this.geozonesControl.filter(
        (el) => el.key === val
      )

      this.notification.controle_geozones = currentControl
    },
    updateIsonecheckbox(val) {
      this.isOneCheckbox = val
    },
    updateTripsnamemask(val) {
      this.isTripsnamemask = val
    },
    updateText(val) {
      this.isTextMissing = val
    },
    updateSensorType(val) {
      this.isSensorTypeOrMask = val
    },
    updateInterval(val) {
      this.isIntervalMissing = val
    },
    updateSensorMoreLess(val) {
      this.isSensorMoreLess = val
    },
    updatePhoneMissing(val) {
      this.phoneMissing = val
    },
    updateEmailMissing(val) {
      this.emailMissing = val
    },
    updatePushMissing(val) {
      this.pushMissing = val
    },
    updateTelegramMissing(val) {
      this.telegramMissing = val
    },
    updateIntervalDaysOrMilage(val) {
      this.isIntervalDaysOrMilage = val
    },
    updateLostSignalNotLess(val) {
      this.isLostSignalNotLessMissing = val
    },
    updateIdleParamsMissing(val) {
      this.isIdleParamsMissing = val
    },
    updateVelocityParams(val) {
      this.isVelocityParamsMissing = val
    },
    updateInGeozone(val) {
      this.isInGeozone = val
    },
    valid() {
      // Основное
      let errors = 0
      if (!this.$refs.main) {
        return errors
      }
      if (this.$refs.main.isNameMissing) {
        errors = 1
      }
      if (this.$refs.main.checkedUnits.length < 1) {
        this.$refs.main.setChosenUnitsError()
        this.isMainError = true
        setTimeout(() => {
          this.isMainError = false
        }, 500)
        errors = 1
      }
      // Дополнительно
      if (
        this.notification.type.key === 'service_notification' &&
        !(
          this.notification.interval_days ||
          this.notification.interval_milage ||
          this.notification.interval_motohours
        )
      ) {
        this.isIntervalDaysOrMilage = !(
          this.notification.interval_days ||
          this.notification.interval_milage ||
          this.notification.interval_motohours
        )
        this.isSecondaryError = true
        setTimeout(() => {
          this.isSecondaryError = false
        }, 500)
        errors = 1
      }
      if (
        this.notification.type.key === 'geozone_notification' &&
        (this.notification.geozones.length < 1 ||
          this.notification.in_geozone === '')
      ) {
        this.isInGeozone = this.notification.in_geozone === ''
        this.notification.geozones.length < 1 && this.$refs.geozone.tableError()
        this.isSecondaryError = true
        setTimeout(() => {
          this.isSecondaryError = false
        }, 500)
        errors = 1
      }
      // значения датчика вроде бы
      const sensorType = this.notification.sensor_type
      if (
        typeof sensorType === 'object' &&
        this.notification.type.key === 'sensor_notification' &&
        !(Object.keys(sensorType).length || this.notification.name_mask)
      ) {
        // removed to verify why it's added since it block all
        //  this.notification.in_interval === '' ||
        // this.notification.sensor_notless === '' ||
        // this.notification.sensor_notmore === ''
        this.isSensorTypeOrMask = !(
          Object.keys(sensorType).length || this.notification.name_mask
        )
        this.isIntervalMissing = this.notification.in_interval === ''

        this.isSensorMoreLess =
          this.notification.sensor_notless === '' ||
          this.notification.sensor_notmore === ''
        this.isSecondaryError = true
        setTimeout(() => {
          this.isSecondaryError = false
        }, 500)
        errors = 1
      }

      if (
        this.notification.type.key === 'velocity_notification' &&
        !(this.notification.speed_notmore || this.notification.speed_notless)
      ) {
        this.isVelocityParamsMissing = !(
          this.notification.speed_notmore || this.notification.speed_notless
        )
        this.isSecondaryError = true
        setTimeout(() => {
          this.isSecondaryError = false
        }, 500)
        errors = 1
      }
      if (
        this.notification.type.key === 'lostsignal_notification' &&
        !this.notification.lostsignal_notless
      ) {
        this.isLostSignalNotLessMissing = !this.notification.lostsignal_notless
        this.isSecondaryError = true
        setTimeout(() => {
          this.isSecondaryError = false
        }, 500)
        errors = 1
      }
      if (
        this.notification.type.key === 'idle_notification' &&
        !(this.notification.duration || this.notification.speed_notmore)
      ) {
        this.isIdleParamsMissing = !(
          this.notification.duration || this.notification.speed_notmore
        )
        this.isSecondaryError = true
        setTimeout(() => {
          this.isSecondaryError = false
        }, 500)
        errors = 1
      }
      if (
        this.notification.type.key === 'trips_notification' &&
        /^\s+$/.test(this.notification.trips_name_mask)
      ) {
        this.isTripsnamemask = true
        this.isSecondaryError = true
        setTimeout(() => {
          this.isSecondaryError = false
        }, 500)
        errors = 1
      }
      if (
        this.notification.type.key === 'trips_notification' &&
        !(
          this.notification.is_start_trip ||
          this.notification.is_end_trip ||
          this.notification.is_in_trip_points ||
          this.notification.is_in_tp_violation ||
          this.notification.is_out_trip_points ||
          this.notification.is_out_tp_violation ||
          this.notification.is_skip_trip_points
        )
      ) {
        this.isOneCheckbox = true
        this.isSecondaryError = true
        setTimeout(() => {
          this.isSecondaryError = false
        }, 500)
        errors = 1
      }

      if (!this.notification.notificationtext) {
        this.isTextMissing = !this.notification.notificationtext
        this.isTextError = true
        setTimeout(() => {
          this.isTextError = false
        }, 500)
        errors = 1
      }

      // Delivery
      if (!this.deliveryValid) {
        this.isDeliveryError = true
        setTimeout(() => {
          this.isDeliveryError = false
        }, 500)
        errors = 1
      }

      return errors
    },
    save() {
      if (!this.valid()) {
        this.notification.units = this.$refs.main.checkedUnits.map((id) => {
          return {
            id
          }
        })
        this.title === 1
          ? this.$store
              .dispatch('notifications/CREATE', this.notification)
              .then((_) => this.$emit('close'))
          : this.$store
              .dispatch('notifications/UPDATE', this.notification)
              .then((_) => this.$emit('close'))
      }
    }
  },
  created() {
    this.notification =
      this.title === 1
        ? {
            // ОСНОВНАЯ

            name: this.$t('notification'),
            type: this.notificationTypes[0],
            is_active: false,
            units: [],
            // ДОПОЛНИТЕЛЬНО
            sound: {
              key: 'no_sound',
              value: this.$t('notifications.no_sound')
            },
            // КОНТРОЛЬ СКОРОСТИ
            speed_notless: '',
            speed_notmore: '',
            use_speed_limits: false,
            // КОНТРОЛЬ ГЕОЗОН
            in_geozone: '',
            geozones: [],
            // КНТРОЛЬ РЕЙСОВ
            trips_name_mask: '',
            is_start_trip: false,
            is_end_trip: false,
            is_in_trip_points: false,
            is_in_tp_violation: false,
            is_out_trip_points: false,
            is_out_tp_violation: false,
            is_skip_trip_points: false,
            // КОНТРОЛЬ ЗНАЧЕНИЯ ДАТЧИКА
            sensor_type: {},
            name_mask: '',
            sensor_notless: '',
            sensor_notmore: '',
            in_interval: '',
            // ПОТЕРЯ СВЯЗИ
            lostsignal_notless: '',
            // ПРОСТОЙ ТРАНСПОРТНОГО СРЕДСТВА
            duration: '',
            // УВЕДОМЛЯТЬ О ПРИБЛИЖЕНИИ СРОКА ПЛАНОВОГО ТО
            interval_days: '',
            interval_milage: '',
            // ДОСТАВКА
            isdeliverysms: false,
            isdeliveryemail: false,
            isdeliverypush: false,
            isdeliverytelegram: false,
            delivery_sms: [],
            delivery_emails: [],
            delivery_push: [],
            delivery_telegram: [],
            is_show_online: false,
            isviolation: false,
            isevent: false,
            // ТЕКСТ
            notificationtext: '',
            // КОНТРОЛЬ
            weeks_control: false,
            wc_active_1: false,
            wc_active_2: false,
            wc_active_3: false,
            wc_active_4: false,
            wc_active_5: false,
            wc_active_6: false,
            wc_active_7: false,
            wc_from_1: '00:00',
            wc_from_2: '00:00',
            wc_from_3: '00:00',
            wc_from_4: '00:00',
            wc_from_5: '00:00',
            wc_from_6: '00:00',
            wc_from_7: '00:00',
            wc_to_1: '23:59',
            wc_to_2: '23:59',
            wc_to_3: '23:59',
            wc_to_4: '23:59',
            wc_to_5: '23:59',
            wc_to_6: '23:59',
            wc_to_7: '23:59'
          }
        : this.notificationToUpdate
  }
}
</script>
